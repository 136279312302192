export class GeneratedRecord {
    id: number;
    title: string;
    status: string;
    input: string;
    analog_input: string | null;
    output: string;
    rating: number | null;
    comment: string | null;
    created_at: Date;

    constructor(
        id: number,
        title: string,
        status: string,
        input: string,
        analog_input: string | null,
        output: string,
        rating: number | null,
        comment: string | null,
        created_at: Date
    ) {
        this.id = id;
        this.title = title;
        this.status = status;
        this.input = input;
        this.analog_input = analog_input;
        this.output = output;
        this.rating = rating;
        this.comment = comment;
        this.created_at = created_at;
    }
}


