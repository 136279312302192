import React, { useState, useEffect, useRef } from 'react';
import { Alert, Box, Button, CircularProgress, Paper, Snackbar, TextField, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../services/apiService";
import RecordTableComponent from "./recordTable/RecordTableComponent";
import { useAuth } from "../../hooks/useAuth";
import ImageUploadButtonComponent from "./ImageUploadButtonComponent/ImageUploadButtonComponent";
import { GeneratedRecord } from "../../models/GeneratedRecord";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './ResponderComponent.css';
import { BEANSTANDUNG_INFO_TEXT, ANALOG_INFO_TEXT, VORGANSNAME_INFO_TEXT } from "../../constants/generalConstants";


// Definieren Sie eine Schnittstelle für die Formularwerte
interface FormValues {
    title: string;
    answer: string;
    analog: string;
}

// Anfangswerte basierend auf der Schnittstelle
const initialValues: FormValues = { title: '', answer: '', analog: '' };

// Yup-Schema für die Validierung basierend auf der Schnittstelle
const validationSchema = Yup.object({
    title: Yup.string().required("Ein Name ist erforderlich."),
    answer: Yup.string().required('Eine Antwort ist erforderlich.'),
    analog: Yup.string(),
});

const ResponderComponent: React.FC = () => {
    useAuth();
    const [openSubmitSnackbar, setOpenSubmitSnackbar] = useState<boolean>(false);
    const [openFetchedSnackbar, setOpenFetchedSnackbar] = useState<boolean>(false);
    const [openDeletedSnackbar, setOpenDeletedSnackbar] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteRecordId, setDeleteRecordId] = useState<number | null>(null);
    const [records, setRecords] = useState<GeneratedRecord[]>([]);
    const recordLength = useRef<number>(0);

    const resetFields = (values: FormValues) => {
        values.title = '';
        values.answer = '';
        values.analog = '';
    }

    const handleClose = (
        setOpen: React.Dispatch<React.SetStateAction<boolean>>) => (event: React.SyntheticEvent | Event,
            reason?: string
        ) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpen(false);
        };

    useEffect(() => {
        const fetchRecords = async () => {
            try {
                const fetchedRecords = await ApiService.fetchGeneratedRecords();
                const sortedRecords = fetchedRecords.sort((a, b) => b.id - a.id);
                if (sortedRecords.length > recordLength.current) {
                    setOpenFetchedSnackbar(true);
                    setRecords(sortedRecords);
                } else if (statusChanged(sortedRecords, records)) {
                    setRecords(sortedRecords);
                }
                recordLength.current = sortedRecords.length;
            } catch (error) {
                console.error('Fehler beim Abrufen der generierten Aufzeichnungen: ', error);
            }
        };

        fetchRecords();
        const interval = setInterval(fetchRecords, 15 * 1000);
        return () => clearInterval(interval);
    }, []);

    const statusChanged = (newRecords: GeneratedRecord[], currentRecords: GeneratedRecord[]): boolean => {
        const newStatuses = newRecords.map(record => ({ id: record.id, status: record.status }));
        const currentStatuses = currentRecords.map(record => ({ id: record.id, status: record.status }));
        if (newStatuses.length !== currentStatuses.length) {
            return true;
        }
        for (let i = 0; i < newStatuses.length; i++) {
            if (newStatuses[i].status !== currentStatuses[i].status) {
                return true;
            }
        }

        return false;
    };

    // Funktion, die beim Submit ausgeführt wird
    const handleSubmit = async (
        values: FormValues,
        { setSubmitting, resetForm }: FormikHelpers<FormValues>
    ) => {
        setSubmitting(true);

        try {
            setOpenSubmitSnackbar(true);
            const fetchedRecords = await ApiService.analyzeText(values.title, values.answer, values.analog);
            const sortedRecords = fetchedRecords.sort((a, b) => b.id - a.id);
            setRecords(sortedRecords);
            recordLength.current = sortedRecords.length;
            resetFields(values);
        } catch (error) {
            console.error('Es gab einen Fehler beim Senden der Daten', error);
        }

        setSubmitting(false);
    };

    const handleUploadSuccess = (imageText: string, identifier: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        if (identifier === 'analog') {
            setFieldValue('analog', imageText);
        } else {
            setFieldValue('answer', imageText);
        }
    };

    const handleDeleteClick = (id: number) => {
        setOpenDeleteDialog(true);
        setDeleteRecordId(id);
    };

    const handleConfirmDelete = async () => {
        if (deleteRecordId !== null) {
            try {
                await ApiService.deleteRecord(deleteRecordId);
                const updatedRecords = records.filter(record => record.id !== deleteRecordId);
                setRecords(updatedRecords);
                setOpenDeletedSnackbar(true);
            } catch (error) {
                console.error('Fehler beim Löschen des Eintrags: ', error);
            }
        }
        setOpenDeleteDialog(false);
        setDeleteRecordId(null);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeleteRecordId(null);
    };

    const handleTitleUpdate = async (id: number, title: string) => {
        try {
            await ApiService.editRecordTitle(id, title);
            const updatedRecords = records.map((record) =>
                record.id === id ? { ...record, title: title } : record
            );
            setRecords(updatedRecords);
        } catch (error) {
            console.error('Error updating the record title:', error);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Paper elevation={4} sx={{ p: 2, mt: 2, mb: 2, width: '70%', maxWidth: '1200px' }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleBlur, isSubmitting, touched, errors, setFieldValue }) => (
                        <Form>
                            <Typography variant="h4" component="h1" sx={{ textAlign: 'center', my: 4 }}>
                                MediDefend Cockpit
                            </Typography>
                            <div className="headline-container">
                                <div className="fw-bold">Vorgangsname</div>
                                <div className="help-icon-container">
                                    <Tooltip title={VORGANSNAME_INFO_TEXT} arrow>
                                        <HelpOutlineIcon />
                                    </Tooltip>
                                </div>
                            </div>
                            <TextField
                                name="title"
                                placeholder="Vorgangsname"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.title && Boolean(errors.title)}
                                helperText={touched.title && errors.title}
                                sx={{ mb: 2 }}
                            />
                            <div className="headline-container">
                                <div className="fw-bold">Beanstandung der Versicherung</div>
                                <div className="help-icon-container">
                                    <Tooltip title={BEANSTANDUNG_INFO_TEXT} arrow>
                                        <HelpOutlineIcon />
                                    </Tooltip>
                                </div>
                            </div>
                            <TextField
                                name="answer"
                                placeholder="Beanstandung hier eingeben..."
                                multiline
                                rows={3}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={values.answer}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.answer && Boolean(errors.answer)}
                                helperText={touched.answer && errors.answer}
                                sx={{ mb: 2 }}
                            />
                            <ImageUploadButtonComponent buttonTitle={'Normal Bild hochladen'}
                                identifier={'non-analog'}
                                onUploadSuccess={(imageText, identifier) => handleUploadSuccess(imageText, identifier, setFieldValue)}
                            />
                            <div className="headline-container">
                                <div className="fw-bold">Analoge Rechnungspunkte</div>
                                <div className="help-icon-container">
                                    <Tooltip title={ANALOG_INFO_TEXT} arrow>
                                        <HelpOutlineIcon />
                                    </Tooltip>
                                </div>
                            </div>
                            <TextField
                                name="analog"
                                placeholder="Rechnung im Falle von analogen GOZ Nummern"
                                className="mb-1"
                                multiline
                                rows={3}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={values.analog}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.analog && Boolean(errors.analog)}
                                helperText={touched.analog && errors.analog}
                                sx={{ mb: 2 }}
                            />
                            <div className='mt-1'>
                                <ImageUploadButtonComponent buttonTitle={'Analog Bild hochladen'}
                                    identifier={'analog'}
                                    onUploadSuccess={(imageText, identifier) => handleUploadSuccess(imageText, identifier, setFieldValue)}
                                />
                            </div>
                            <Button className='mt-1' type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth sx={{ mb: 2 }}>
                                {isSubmitting ? <CircularProgress size={24} /> : 'Senden'}
                            </Button>
                        </Form>
                    )}
                </Formik>
                <div className="divider"></div>
                <Typography variant="h4" component="h1" sx={{ textAlign: 'center', my: 4 }}>
                    Ergebnisse
                </Typography>
                <RecordTableComponent records={records} onRecordDelete={handleDeleteClick} onRecordTitleUpdate={handleTitleUpdate} />
                <Snackbar
                    open={openSubmitSnackbar}
                    autoHideDuration={2000}
                    onClose={handleClose(setOpenSubmitSnackbar)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Formular erfolgreich abgesendet!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={openFetchedSnackbar}
                    autoHideDuration={2000}
                    onClose={handleClose(setOpenFetchedSnackbar)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Neue Einträge wurden geladen
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={openDeletedSnackbar}
                    autoHideDuration={2000}
                    onClose={handleClose(setOpenDeletedSnackbar)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Eintrag erfolgreich gelöscht
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">{'Eintrag löschen?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog}>Abbrechen</Button>
                        <Button onClick={handleConfirmDelete} color="error">
                            Löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Box>
    );
};

export default ResponderComponent;
