import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Ensures, that the user is logged in, otherwise redirects to login.
 *
 * Hook first checks if the url token is valid. If so, it will be set as the current accessToken in the local storage.
 * If the url token is not valid it checks the refresh token in local storage, and if this one is invalid, redirect the user to the login page.
 *
 * @example
 * Adding the hook to your component makes the component dependant on your login status.
 * ```tsx
 * const ExampleComponent = () => {
 *  useAuth();
 * }
 * ```
 */
export function useAuth() {
    const navigate = useNavigate();
    const location = useLocation();

    const [invalidateLogin, setInvalidateLogin] = useState(false);

    useEffect(() => {
        if (invalidateLogin) {
            navigate("/login");
        }

        return () => {
            setInvalidateLogin(false);
        };
    }, [invalidateLogin]);

    const isTokenActive = (token: any) => {
        if (token) {
            try {
                const decodedToken: any = jwtDecode(token);
                const currentTime = Date.now() / 1000;
                return decodedToken.exp > currentTime;
            } catch (error) {
                return false;
            }
        }
        return false;
    };

    const checkAuthentication = () => {
        const queryParams = new URLSearchParams(location.search);
        const urlToken = queryParams.get("token");

        if (urlToken && isTokenActive(urlToken)) {
            localStorage.setItem("accessToken", urlToken);
            localStorage.removeItem("refreshToken");
            return;
        }

        if (
            !isTokenActive(localStorage.getItem("refreshToken")) &&
            !invalidateLogin
        ) {
            setInvalidateLogin(true);
        }
    };

    checkAuthentication();
}