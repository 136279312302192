import React from 'react';
import Button from '@mui/material/Button';
import ApiService from "../../../services/apiService";

interface ImageUploadButtonComponentProps {
    buttonTitle: string;
    identifier: string;
    onUploadSuccess: (imageText: string, identifier: string) => void;
}
const ImageUploadButtonComponent: React.FC<ImageUploadButtonComponentProps> = ({buttonTitle, identifier , onUploadSuccess}) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = () => {
        hiddenFileInput.current?.click();
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const imageText = await ApiService.uploadImage(file)
                onUploadSuccess(imageText, identifier);
            } catch (error) {
                console.error('error when upload file:', error);
            }
        }
    };

    return (
        <>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
            <Button variant="contained" color="primary" onClick={handleClick}>
                {buttonTitle}
            </Button>
        </>
    );
};

export default ImageUploadButtonComponent;
