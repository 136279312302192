import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import ApiService from "../../services/apiService";
import {useNavigate} from "react-router-dom";

// Validierungsschema mit Yup
const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .required('Benutzername ist erforderlich'),
    password: Yup.string()
        .required('Passwort ist erforderlich'),
});

// Login Komponente
const LoginComponent: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    paddingTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={LoginSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        try {
                            await ApiService.loginUser(values.username, values.password);
                            navigate('/');
                        } catch (error) {
                            alert('Fehler beim Einloggen. Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort.');
                            console.error('Einloggen fehlgeschlagen:', error);
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ isSubmitting, handleChange, touched, errors }) => (
                        <Form>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Benutzername"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onChange={handleChange}
                                error={touched.username && Boolean(errors.username)}
                                helperText={touched.username && errors.username}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Passwort"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Einloggen
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Container>
    );
};

export default LoginComponent;
