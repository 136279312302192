import React, { useState } from 'react';
import { Button, Paper, LinearProgress, Box, Typography, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { GeneratedRecord } from "../../../models/GeneratedRecord";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DetailComponent from "./DetailComponent/DetailComponent";
import { useAuth } from "../../../hooks/useAuth";
import Rating from "@mui/material/Rating";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface RecordTableComponentProps {
    records: GeneratedRecord[];
    onRecordDelete: (id: number) => void;
    onRecordTitleUpdate: (id: number, title: string) => void;
}

const RecordTableComponent: React.FC<RecordTableComponentProps> = ({ records, onRecordDelete, onRecordTitleUpdate }) => {
    useAuth();
    const [selectedRecord, setSelectedRecord] = useState<GeneratedRecord | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [editTitle, setEditTitle] = useState<string>('');
    const [detailViewOpen, setDetailViewOpen] = useState<boolean>(false);


    const renderStatusComponent = (status: string): JSX.Element => {
        if (status === 'SUCCESS') {
            return <CheckCircleOutlineIcon style={{ color: 'green' }} />;
        } else if (status === 'ERROR') {
            return <ErrorOutlineIcon style={{ color: 'red' }} />;
        } else if (!isNaN(parseFloat(status)) && parseFloat(status) >= 0 && parseFloat(status) <= 100) {
            const progress = parseFloat(status);
            return (
                <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
                    </Box>
                </Box>
            );
        } else {
            return <div>Unbekannter Status</div>;
        }
    };

    const handleCloseDetailView = () => {
        setDetailViewOpen(false);
        setSelectedRecord(null);
    };

    const handleSelectRecord = (record: GeneratedRecord) => {
        setSelectedRecord(record);
    };

    const handleEditTitleClick = (record: GeneratedRecord) => {
        setEditTitle(record.title);
        setSelectedRecord(record);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleCancelEdit = () => {
        setSelectedRecord(null);
        setIsEditModalOpen(false);
    };

    const handleSaveEdit = () => {
        if (selectedRecord) {
            onRecordTitleUpdate(selectedRecord.id, editTitle);
            setSelectedRecord(null);
            setIsEditModalOpen(false);
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader size='small' aria-label="sticky table" sx={{ tableLayout: 'fixed' }}>
                    <colgroup>
                        <col width="35%" />
                        <col width="18%" />
                        <col width="17%" />
                        <col width="25%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>Vorgangsname</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Bewertung</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((record) => (
                            <TableRow
                                key={record.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <Tooltip
                                        title={
                                            <>
                                                <div>{record.title}</div>
                                                <br/>
                                                <div>(Zum Bearbeiten klicken)</div>
                                            </>
                                        }
                                    >
                                        <span onClick={() => handleEditTitleClick(record)}
                                            style={{ cursor: 'pointer' }}>
                                            {record.title}
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align='center'>
                                    {renderStatusComponent(record.status)}
                                </TableCell>
                                <TableCell align="center">
                                    {record.rating && (
                                        <Rating
                                            readOnly={true}
                                            value={record.rating}
                                        />
                                    )}
                                    {!record.rating && (
                                        <div>-</div>
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        onClick={() => {
                                            handleSelectRecord(record)
                                            setDetailViewOpen(true);
                                        }}
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2 }}
                                        disabled={record.status !== 'SUCCESS' && record.status !== 'ERROR'}
                                    >
                                        Details ansehen
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2, ml: 1 }}
                                        color="error"
                                        onClick={() => onRecordDelete(record.id)}
                                        disabled={record.status !== 'SUCCESS' && record.status !== 'ERROR'}
                                    >
                                        LÖSCHEN
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedRecord && detailViewOpen && (
                <DetailComponent
                    open={Boolean(selectedRecord)}
                    onClose={handleCloseDetailView}
                    recordId={selectedRecord.id}
                    onRecordTitleUpdate={onRecordTitleUpdate}
                />
            )}
            <Dialog open={isEditModalOpen} onClose={handleCloseEditModal} aria-labelledby="edit-title-dialog">
                <DialogTitle>Titel bearbeiten</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Neuer Titel"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelEdit}>Abbrechen</Button>
                    <Button onClick={handleSaveEdit} color="primary">Speichern</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default RecordTableComponent;
