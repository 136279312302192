// src/services/apiService.ts
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import { GeneratedRecord } from '../models/GeneratedRecord';
import { BACKEND_URL } from "../constants/apiConstants";


// Stellen Sie die Axios-Instanz ein
const apiInstance = axios.create({
    baseURL: BACKEND_URL,
    // Hier können Sie weitere Konfigurationen vornehmen, z.B. Headers
});

/**
 * Interceptor that checks whether or not the accessToken exists and if it does it will add it as an
 * Authorization bearer token.
 *
 * @returns axios header authorization configuration
 */
apiInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

/**
 * Lets you refresh your access token, if it is expired.
 *
 * @param refreshToken Token, that lets you create a new access token.
 * @returns Object with new access and refresh token.
 */
export const refreshLogin = (refreshToken: string) => {
    return apiInstance.post("/api/token/refresh/", {
        refresh: refreshToken,
    });
};

/**
 * Interceptor, that automatically handles 401 (unauthorized) errors by attempting to refresh the
 * access token and retry the original request. If succeeded the response is returned directly without
 * any modifications. If not succeeded the interceptor tries to refresh the token with the refreshLogin method.
 */
apiInstance.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
        return response;
    },
    async (error: AxiosError): Promise<any> => {
        const originalRequest = error.config as AxiosRequestConfig & {
            _retry?: boolean;
        };
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken: string | null = localStorage.getItem("refreshToken");
            if (refreshToken) {
                return refreshLogin(refreshToken)
                    .then((response) => {
                        if (!originalRequest.headers) {
                            originalRequest.headers = {};
                        }

                        localStorage.setItem("accessToken", response.data.access);
                        originalRequest.headers[
                            "Authorization"
                            ] = `Bearer ${response.data.access}`;

                        return apiInstance(originalRequest);
                    })
                    .catch((error) => {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("refreshToken");
                        console.error("Error refreshing the access token:", error);
                        return Promise.reject(error);
                    });
            }
        }
        return Promise.reject(error);
    }
);

// Service-Objekt
const ApiService = {
    loginUser: async (username: string, password: string): Promise<void> => {
        try {
            const response = await apiInstance.post(`/api/token/`, {
                username: username,
                password: password
            });
            const { access, refresh } = response.data;
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
        } catch (error) {
            throw error;
        }
    },
    // Methode zum Senden einer Antwort
    analyzeText: async (title: string, answer: string, analog: string): Promise<GeneratedRecord[]> => {
        try {
            const response = await apiInstance.post(`/api/analyze-text/`, { title: title, answer: answer, analog: analog });
            const data = response.data;

            console.log(data.mesage)
            
            return data.map((record: GeneratedRecord) => new GeneratedRecord(
                record.id,
                record.title,
                record.status,
                record.input,
                record.analog_input,
                record.output,
                record.rating,
                record.comment,
                new Date(record.created_at)
            ));
        } catch (error) {
            // Hier Fehlerbehandlung, könnte auch eine spezifische Fehlermeldung oder ein Fehlerobjekt zurückgeben
            throw error;
        }
    },

    // Weitere Methoden für andere Endpunkte

    sendRating: async (record: GeneratedRecord): Promise<GeneratedRecord> => {
        try {
            const response = await apiInstance.put(`/api/send-rating/`, { record: record });
            const data = response.data;
            return new GeneratedRecord(data.id, data.title, data.status, data.input, data.analog_input, data.output, data.rating, data.comment, new Date(data.created_at));
        } catch (error) {
            throw error;
        }
    },

    fetchGeneratedRecords: async (): Promise<GeneratedRecord[]> => {
        try {
            const response = await apiInstance.get(`/api/get-records/`);
            const data = response.data;

            return data.map((record: GeneratedRecord) => new GeneratedRecord(
                record.id,
                record.title,
                record.status,
                record.input,
                record.analog_input,
                record.output,
                record.rating,
                record.comment,
                new Date(record.created_at)
            ));
        } catch (error) {
            throw error;
        }
    },

    fetchGeneratedRecordById: async (record_id: number): Promise<GeneratedRecord> => {
        try {
            const response = await apiInstance.get(`/api/get-records/${record_id}/`);
            const data = response.data;

            return new GeneratedRecord(
                data.id,
                data.title,
                data.status,
                data.input,
                data.analog_input,
                data.output,
                data.rating,
                data.comment,
                new Date(data.created_at)
            );
        } catch (error) {
            throw error;
        }
    },

    uploadImage: async (imageFile: File): Promise<string> => {
        try {
            const formData = new FormData();
            formData.append("file", imageFile);

            const response = await apiInstance.post(`/api/upload/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Image uploaded successfully:', response.data);
            return response.data.extracted;
        } catch (error) {
            throw error;
        }
    },

    deleteRecord: async (record_id: number): Promise<void> => {
        try {
            await apiInstance.delete(`/api/get-records/${record_id}/`);
            console.log(`Record with id ${record_id} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting the record:', error);
            throw error;
        }
    },

    editRecordTitle: async (record_id: number, new_title: string): Promise<void> => {
        try {
            const payload = {
                title: new_title
            };
    
            await apiInstance.patch(`/api/get-records/${record_id}/`, payload);
            console.log(`Record with id ${record_id} successfully updated.`);
        } catch (error) {
            console.error('Error editing title of the record:', error);
            throw error;
        }
    },

};

export default ApiService;
