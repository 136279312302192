import React from 'react';
import './App.css';
import ResponderComponent from "./components/simpleResponder/ResponderComponent";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import LoginComponent from "./components/LoginComponent/LoginComponent";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<ResponderComponent/>}/>
                    <Route path="/login" element={<LoginComponent/>}/>
                </Routes>
            </Router>
        </div>
    );
}


export default App;
