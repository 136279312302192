import React, {useEffect, useState} from 'react';
import {Button, Modal, Paper, TextField, IconButton, InputAdornment, Tooltip } from '@mui/material';
import {GeneratedRecord} from "../../../../models/GeneratedRecord";
import Rating from "@mui/material/Rating";
import ApiService from "../../../../services/apiService";
import './DetailComponent.css'
import apiService from "../../../../services/apiService";
import {useAuth} from "../../../../hooks/useAuth";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { NumberSchema } from 'yup';


interface DetailComponentProps {
    open: boolean;
    onClose: () => void;
    recordId: number;
    onRecordTitleUpdate: (id: number, title: string) => void;
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    overflow: 'auto',
    maxHeight: '90vh',
    p: 4,
    outline: 'none',
};


const DetailComponent: React.FC<DetailComponentProps> = ({open, onClose, recordId, onRecordTitleUpdate}) => {
    useAuth();
    const [ratedSuccessfully, setRatedSuccessfully] = useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<GeneratedRecord>();
    const [title, setTitle] = useState<string>('');
    const [originalTitle, setOriginalTitle] = useState<string>('');
    const [rating, setRating] = React.useState<number | null>(0);
    const [comment, setComment] = useState<string>('');
    const [isTitleEditMode, setIsTitleEditMode] = useState<boolean>(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (recordId) {
                    const fetchedRecord = await apiService.fetchGeneratedRecordById(recordId);
                    setSelectedRecord(fetchedRecord);
                    setTitle(fetchedRecord.title);
                    setOriginalTitle(fetchedRecord.title);  
                    if (fetchedRecord.rating) {
                        setRating(fetchedRecord.rating);
                        if (fetchedRecord.comment) {
                            setComment(fetchedRecord.comment);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching record:', error);
            }
        };

        fetchData();
    }, [recordId]);

    const handleSubmitRating = async () => {
        if (selectedRecord) {
            selectedRecord.rating = rating;
            selectedRecord.comment = comment;

            try {
                await ApiService.sendRating(selectedRecord);
                setRatedSuccessfully(true);
            } catch (error) {
                console.error('Es gab einen Fehler beim Senden der Bewertung', error);
            }
        }
    }

    const handleEditClick = () => {
        setIsTitleEditMode(true);
    };

    const cancelEdit = () => {
        setTitle(originalTitle);
        setIsTitleEditMode(false);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const submitTitleChange = async () => {
        onRecordTitleUpdate(recordId, title);
        setIsTitleEditMode(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper sx={modalStyle}>
                {selectedRecord && (
                    <>
                        <TextField
                            name="title"
                            placeholder="Vorgangsname"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={title}
                            onChange={handleTitleChange}
                            sx={{mb: 2}}
                            InputProps={{
                                readOnly: !isTitleEditMode,
                                endAdornment: isTitleEditMode ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="confirm title edit"
                                            onClick={submitTitleChange}
                                            edge="end"
                                            size="small"
                                            style={{ color: 'green' }}
                                        >
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="cancel title edit"
                                            onClick={cancelEdit}
                                            edge="end"
                                            size="small"
                                            style={{ color: 'red' }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    <InputAdornment position="end">
                                        <Tooltip title="Titel bearbeiten">
                                            <IconButton
                                                aria-label="toggle title edit"
                                                onClick={handleEditClick}
                                                edge="end"
                                                size="small"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            inputProps={{ readOnly: true }}
                            name="answer"
                            multiline
                            rows={3}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={selectedRecord.input}
                            sx={{mb: 2}}
                        />
                        {selectedRecord.analog_input && (
                            <TextField
                                inputProps={{ readOnly: true }}
                                name="analog"
                                placeholder="Rechnung im Falle von analogen GOZ Nummern"
                                multiline
                                rows={3}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={selectedRecord.analog_input}
                                sx={{mb: 2}}
                            />
                        )}
                        <div className="divider"></div>
                        <TextField
                            inputProps={{ readOnly: true }}
                            name="answer"
                            multiline
                            rows={12}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={selectedRecord.output}
                            sx={{mb: 2}}
                        />
                        <div>
                            <h2>Wie hilfreich war diese Antwort?</h2>
                            <Rating
                                name="simple-controlled"
                                value={rating}
                                disabled={selectedRecord.rating !== null}
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                            />
                            <TextField
                                name="comment"
                                placeholder="Kommentar...."
                                multiline
                                rows={3}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={comment}
                                inputProps={{ readOnly: selectedRecord.comment !== null }}
                                onChange={(event) => setComment(event.target.value)}
                                sx={{mb: 2}}
                            />
                        </div>
                        {ratedSuccessfully && (
                            <div>
                                <h2 className='rating-success'>Danke für Ihre Bewertung!</h2>
                            </div>
                        )}
                        <div className="modal-button-container">
                            {selectedRecord.rating === null && !ratedSuccessfully && (
                                <Button
                                    onClick={handleSubmitRating}
                                    variant="contained"
                                    color="primary"
                                    disabled={!rating}
                                >
                                    Bewertung absenden
                                </Button>
                            )}
                            <Button sx={{ml: 2}} onClick={onClose} variant="outlined">
                                Schließen
                            </Button>
                        </div>
                    </>
                )}
            </Paper>
        </Modal>
    );
};

export default DetailComponent;
